import React, { useMemo, MouseEventHandler, useEffect, useState, useRef } from "react"
import styled, { css } from "styled-components"
import symbolImage from '@/images/symbol.svg'
import { PageRendererProps, navigate } from "gatsby"
import animatedScroll, { easeInOutCubic } from "@/utils/animated-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

type WindowLocation = PageRendererProps['location']

interface NavigationProps {
  location: WindowLocation
}

type LinkItem = {
  label: string
  href: string
}

type ParentItem = {
  label: string
  children: LinkItem[]
}

type MenuItem = LinkItem | ParentItem

const Navigation: React.FC<NavigationProps> = ({ location }) => {
  const menuItems: MenuItem[] = useMemo(() => ([
    {
      label: 'Top',
      href: '/'
    },
    {
      label: 'Services',
      children: [
        {
          label: 'ZEN Advisor',
          href: '/zen-advisor'
        },
        {
          label: 'DevOps with GitHub',
          href: '/devops-github'
        },
        {
          label: 'Azure Light-up',
          href: '/hackathon'
        }
      ]
    },
    {
      label: 'Blog',
      href: '/posts'
    },
    {
      label: 'Corporate',
      href: '/corporate-profile'
    },
    {
      label: 'Contact',
      href: '/contacts'
    }
  ]), [])

  const isExpandPage = ['/', '/hackathon', '/zen-advisor', '/devops-github'].includes(location.pathname)
  const [isExpanded, setIsExpanded] = useState(isExpandPage)

  useEffect(() => {
    setIsExpanded(isExpandPage && pageYOffset < 220)
    const handleScroll = () => {
      setIsExpanded(isExpandPage && pageYOffset < 220)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [location.pathname])

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleClickLogo: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault()
    setMenuIsOpen(false)
    if (location.pathname === '/') {
      animatedScroll(pageYOffset, 0, 1000, easeInOutCubic)
    } else {
      navigate('/')
    }
  }

  const handleClickToggleMenuButton: MouseEventHandler<HTMLButtonElement> = () => {
    setMenuIsOpen((previous) => !previous)
  }

  const handleClickMenuLinkItem = (event: Parameters<MouseEventHandler<HTMLAnchorElement>>[0], item: LinkItem) => {
    if (["/devops-github", "/zen-advisor"].includes(item.href)) {
      return;
    }

    event.preventDefault()
    setMenuIsOpen(false)
    setOpenParentItemIndex(null)
    navigate(item.href)
  }  

  const [openParentItemIndex, setOpenParentItemIndex] = useState<number | null>(null)

  const menuListDOMRef = useRef<HTMLUListElement>(null)

  return (
    <Root isExpanded={isExpanded}>
      <Container>
        <Title>
          <LogoLink href="/" onClick={handleClickLogo}>
            <Logo src={symbolImage} />
          </LogoLink>
          <ToggleMenuButton onClick={handleClickToggleMenuButton} isOpen={menuIsOpen}>
            <ToggleMenuButtonIcon reverse={menuIsOpen}>
              <FontAwesomeIcon icon={faAngleDown} />
            </ToggleMenuButtonIcon>
            <ToggleMenuButtonLabel>
              Menu
            </ToggleMenuButtonLabel>
          </ToggleMenuButton>
        </Title>
        <Menu isOpen={menuIsOpen}>
          <MenuList ref={menuListDOMRef}>
            {
              menuItems.map((item, index) => {
                if (isParentItem(item)) {
                  const open = openParentItemIndex === index
                  return (
                    <MenuListItem key={index} className="parent">
                      <MenuListItemParentButton
                        isOpen={open}
                        isActive={item.children.map(child => child.href).includes(location.pathname)}
                        onClick={() => setOpenParentItemIndex(open ? null : index)}
                      >
                        {item.label}
                        <span className="icon">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </MenuListItemParentButton>
                      {
                        open && <div className="children">
                          {item.children.map((child, index) => {
                            return (
                              <MenuListItemLink
                                className="child"
                                key={index}
                                href={child.href}
                                onClick={event => handleClickMenuLinkItem(event, child)}
                              >
                                {child.label}
                              </MenuListItemLink>
                            )
                          })}
                        </div>
                      }
                    </MenuListItem>
                  )
                } else {
                  return (
                    <MenuListItem key={index}>
                      <MenuListItemLink
                        href={item.href}
                        isActive={item.href === location.pathname}
                        onClick={event => handleClickMenuLinkItem(event, item)}
                      >
                        {item.label}
                      </MenuListItemLink>
                    </MenuListItem>
                  )
                }
              })
            }
          </MenuList>
        </Menu>
      </Container>
    </Root>
  )
}

function isParentItem(menuItem: MenuItem): menuItem is ParentItem {
  return (menuItem as any).children?.length > 0
}

const Root = styled.nav<{ isExpanded?: boolean }>`
  background-color: #000;
  z-index: 1030;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  min-height: 50px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media ${(props) => props.theme.minMdUp} {
    padding: 15px 0;
    transition: padding 0.5s;

    ${(props) => props.isExpanded && css`
      padding: 30px 0;
      background: transparent;
      border: none;
    `}
  }
`

const Container = styled.div`
  @media ${(props) => props.theme.maxSmDown} {
    margin-right: auto;
    margin-left: auto;
  }
  @media ${(props) => props.theme.minMdUp} and ${(props) => props.theme.maxMdDown} {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 750px;
  }
  @media ${(props) => props.theme.minLgUp} and ${(props) => props.theme.maxLgDown} {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 970px;
  }
  @media ${(props) => props.theme.minXLUp} {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 1170px;
  }
`

const Title = styled.div`
  @media ${(props) => props.theme.maxSmDown} {
    &:after {
      content: '';
      display: block;
      clear: both;
      width: 100%;
    }
  }
  @media ${(props) => props.theme.minMdUp} {
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
`

const LogoLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.maxSmDown} {
    float: left;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  @media ${(props) => props.theme.minMdUp} {
    height: 50px;
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.5;
    }
  }
`

const ToggleMenuButton = styled.button<{ isOpen: boolean }>`
  @media ${(props) => props.theme.maxSmDown} {
    display: flex;
    align-items: center;
    float: right;
    background-color: transparent;
    font-size: 14px;
    color: ${(props) => props.theme.textLightColor};
    outline: 0;
    height: 50px;
    padding: 15px;
    border-radius: 0;
    appearance: button;
    border: none;
  }

  @media ${(props) => props.theme.minMdUp} {
    display: none;
  }
`

const ToggleMenuButtonLabel = styled.span`
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 10px;
`

const ToggleMenuButtonIcon = styled.div<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  transform: rotate(${(props) => props.reverse ? '180deg' : '0'});
`

const Logo = styled.img`
  height: 30px;
`

const Menu = styled.div<{ isOpen: boolean }>`
  @media ${(props) => props.theme.maxSmDown} {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
    display: block;
    overflow-y: auto;
    border-color: #101010;
    transition-duration: 0.35s;
    transition-property: max-height;
    transition-timing-function: ease;
    max-height: 0;

    &::-webkit-scrollbar { /* Chrome, Safari */
      display: none;
    }
    -ms-overflow-style: none; /* IE, Edge */
    scrollbar-width: none; /* Firefox */

    ${(props) => props.isOpen && css`
      max-height: 100vh;
    `}
  }
  @media ${(props) => props.theme.minMdUp} {
    box-shadow: none;
    width: auto;
    border-top: 0;
    display: block /*!important*/;
    height: auto /*!important*/;
    padding-bottom: 0;
    overflow: visible /*!important*/;
    padding-right: 0;
    padding-left: 0;
    max-height: 340px;
    margin-right: 0;
    margin-left: 0;
    border-color: #101010;
  }
`

const MenuList = styled.ul`
  @media ${(props) => props.theme.maxSmDown} {
    list-style: none;
    padding: 7.5px 0;
    margin: 0 -15px;
  }
  @media ${(props) => props.theme.minMdUp} {
    float: right/*!important*/;
    margin-right: -15px;
  }
`

const MenuListItem = styled.li`
  @media ${(props) => props.theme.maxSmDown} {
    position: relative;
  }
  @media ${(props) => props.theme.minMdUp} {
    position: relative;
    float: left;

    &.parent {
      position: relative;
    }

    .children {
      background-color: #162633;
      border-radius: 2px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      padding: 0;
      position: absolute;
    }
  }
`

const MenuListItemLink = styled.a<{ isActive?: boolean }>`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;

  &.child {
    text-transform: inherit;
    white-space: nowrap;

    @media ${(props) => props.theme.minMdUp} {
      padding: 10px 15px;
    }

    @media ${(props) => props.theme.maxSmDown} {
      padding: 10px 15px 10px 30px;
    }

    & + .child {
      @media ${(props) => props.theme.minMdUp} {
        border-top: 1px solid #4e6d7b;
      }
    }
  }

  @media ${(props) => props.theme.maxSmDown} {
    background-color: transparent;
    text-decoration: none;
    transition: all 0.5s;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
    color: white;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
      outline: 0;
    }

    ${(props) => props.isActive && css`
      color: ${(props) => props.theme.primaryColor};
      background-color: white;
      border-radius: 0;
      &:hover {
        color: ${(props) => props.theme.primaryColor};
      }
    `}
  }
  @media ${(props) => props.theme.minMdUp} {
    text-decoration: none;
    transition: color 0.3s;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: white;

    &:hover {
      color: ${(props) => props.theme.secondaryColor};
      outline: 0;
    }

    ${(props) => props.isActive && css`
      color: ${(props) => props.theme.primaryColor};
    `}
  }
`

const MenuListItemParentButton = styled.button<{ isOpen: boolean, isActive?: boolean }>`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  appearance: none;
  cursor: pointer;
  background: transparent;
  border: none;
  display: block;
  line-height: 20px;
  color: white;
  display: flex;
  align-items: center;

  ${(props) => props.isActive && css`
    color: ${(props) => props.theme.primaryColor};
  `}

  &:focus {
    outline: 0;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 10px 15px;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding: 15px;

    &:hover {
      color: ${(props) => props.theme.secondaryColor};
    }
  }

  .icon {
    margin-left: 10px;
    transition: transform 0.2s ease-in-out;
    transform: rotate(${(props) => props.isOpen ? '180deg' : '0'});
  }
`

export default Navigation
