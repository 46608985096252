import React from "react"
import styled from "styled-components"
import logoImage from '@/images/symbol-logo.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"

const Footer: React.FC = () => (
  <Root>
    <Top>
      <Section>
        <Logo src={logoImage} />
      </Section>
      <Section></Section>
      <Section>
        <StyledLink href="/">
          <LinkIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </LinkIcon>
          トップページ
        </StyledLink>
        <StyledLink href="/hackathon">
          <LinkIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </LinkIcon>
          Azure Light-up
        </StyledLink>
        <StyledLink href="/devops-github">
          <LinkIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </LinkIcon>
          DevOps with GitHub
        </StyledLink>
        <StyledLink href="/zen-advisor">
          <LinkIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </LinkIcon>
          ZEN Advisor
        </StyledLink>
      </Section>
      <Section>
        <StyledLink href="/corporate-profile">
          <LinkIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </LinkIcon>
          会社概要
        </StyledLink>
        <StyledLink href="/privacy-policy">
          <LinkIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </LinkIcon>
          プライバシーポリシー
        </StyledLink>
        <StyledLink href="/contacts">
          <LinkIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </LinkIcon>
          お問い合わせ
        </StyledLink>
      </Section>
    </Top>
    <Bottom>
      <Copyright>© 2020 ZEN ARCHITECTS Co.,Ltd.</Copyright>
      <SNSButtons>
        <SNSButton>
          <Anchor href="https://www.facebook.com/zenarchitects" target="_blank">
            <FontAwesomeIcon icon={faFacebookF} />
          </Anchor>
        </SNSButton>
        <SNSButton>
          <Anchor href="https://twitter.com/zenarchitects" target="_blank">
            <FontAwesomeIcon icon={faTwitter} />
          </Anchor>
        </SNSButton>
      </SNSButtons>
    </Bottom>
  </Root>
)

const Root = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: ${(props) => props.theme.textLightColor};

  @media ${(props) => props.theme.maxSmDown} {
    height: 400px;
  }

  @media ${(props) => props.theme.minMdUp} {
    height: 200px;
  }
`

const SNSButtons = styled.ul`
  list-style: none;
  text-align: center;
`

const SNSButton = styled.li`
  display: inline-block;
`

const Anchor = styled.a`
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  padding: 0 10px;
  font-size: 16px;
  line-height: 1;
  transition: all 0.5s;
  &:hover {
    color: white;
  }
`

const Top = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;

  @media ${(props) => props.theme.maxSmDown} {
    padding: 40px 30px 20px;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding-top: 50px;
    display: flex;
    max-width: 1170px;
  }
`

const Section = styled.div`
  @media ${(props) => props.theme.minMdUp} {
    width: 25%;
  }

  & + & {
    @media ${(props) => props.theme.maxSmDown} {
      padding: 10px 0;
    }

    @media ${(props) => props.theme.minMdUp} {
      padding: 0 20px;
    }
  }
`

const Logo = styled.img`
  height: 30px;
`

const StyledLink = styled.a`
  display: block;
  transition: color 0.3s;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }

  & + & {
    margin-top: 14px;
  }
`

const LinkIcon = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-right: 12px;
  transition: transform 0.3s;
  color: ${(props) => props.theme.primaryColor};

  ${StyledLink}:hover & {
    transform: translateX(3px);
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @media ${(props) => props.theme.minMdUp} {
    padding: 20px 0;
    max-width: 1170px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 20px 30px 40px;
  }
`

const Copyright = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
`

export default Footer
